<template>
  <div>
    <div class="select">
      <img class="china74" src="https://www.ytsyz.com.cn/d/file/p/2023/09-30/cadd7575f4c3a619dce48408138d2db1.jpg" alt="">
      <img class="china74" src="http://www.icesnow.com.cn/skin/icesnow2022/images/guoqing-73.jpg" alt="">
      <!--<input type="text" v-model="inputText" placeholder="输入案由、关键词、法院、当事人、律师...">
      <button @click="selectFn()">搜索</button>-->
    </div>
    <div class="mainContent">
      <div class="jianJie">
        <div class="module-box">
          <div class="box-tittle">
            <h2>关于我们</h2>
          </div>
          <div class="progress-wrap">
            <p>
              上海杨浦区中金金融纠纷法律调解中心于2022年6月登记成立，主要业务为金融纠纷调解，主要解决金融机构中适用于简易流程的批量化纠纷案件的矛盾化解问题。调解工作将运用互联网科技平台，并将调解与诉讼执行进行一体化结合，建立解决金融纠纷的专业化新型模式，满足普惠金融矛盾、纠纷处置的便捷、高效、低费用的要求。
            </p>
            <p>
              上海杨浦区中金金融纠纷法律调解中心现已制定规范的内部合规治理管理机构，所有调解人员经报司法管理部门备案后进行网上公示。同时按照《中华人民共和国人民调解法》要求，所有调解业务处理流程均经网站公开，保证当事各方的知情权。调解中心定期向有关各方报告财务情况，接受捐赠方和主管部门的财务监督。
            </p>
            <p>
              优先开展上海本地金融机构和消费者的金融纠纷案件调解工作，主要协助相关司法机构进行金融纠纷的诉前调解工作。通过线上线下调解业务流程，建立互联网送达、线上存证、案件管理等完整的司法调解科技系统，协同法院、仲裁、公证机构等提升金融纠纷案件的调解工作效率。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      inputText: ''
    }
  },
  methods: {
    selectFn() {

    }
  }
}
</script>
<style scoped>
.mainContent {
  position: relative;
  width: 1200px;
  vertical-align: middle;
  display: inline-block;
}

.select {
  height: 280px;
  background: #ed1c24;
}

input {
  width: 1050px;
  height: 66px;
  border-radius: 5px;
  margin: 25px auto;
  border: 0px solid;
  padding-left: 5px;
  font-size: 18px;
}

button {
  background: black;
  color: white;
  width: 150px;
  height: 66px;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 24px;
  border: 0px solid;
  padding-bottom: 9px;
  padding-left: 24px;
  letter-spacing: 22px;
}

.jianJie {
  background: white;
  padding: 5px 20px;
}

.module-box {
  margin-top: 20px;
  border: 1px solid #e5e5e5;
  border-top: 2px solid #e4393c;
  border-radius: 5px;
}

.china74 {
  width: 1200px;
  margin-bottom: 10px;
}

.box-tittle {
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #e5e5e5;
  padding-left: 180px;
}

h2 {
  float: left;
  margin-left: -160px;
  font-size: 20px;
  color: #e4393c;
  font-weight: 700;
}

.progress-wrap {
  padding: 25px 0;
  text-align: left;
  text-indent: 2em;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
}
</style>
