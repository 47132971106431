<template>
  <div class="main">
    <tab-header :tabs="tabs" @tab-change="tabChange"></tab-header>
    <home-main v-show="currentIndex == 0"></home-main>
    <directory-mediators v-show="currentIndex == 1"></directory-mediators>
    <adjustment-process v-show="currentIndex == 2"></adjustment-process>
    <div class="footer-info">
      <div class="foot_main">
        <div class="foot_left">
          <!--<div>
            <a href="/">网站首页</a>
            | <a href="#" title="隐私声明">隐私声明</a>
            | <a href="#" title="法律声明">法律声明</a>
            | <a href="#" title="使用帮助">使用帮助</a>
            | <a href="#" title="关于我们">关于我们</a>
          </div>-->
          <div>
            <a href="" title="">上海杨浦区中金金融纠纷调解中心</a>
             <!--<a href="#" title="上海市高级中级人民法院">上海市高级中级人民法院</a>-->
          </div>
          <div>
            备案许可证编号：<a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023011700号
              沪ICP备2023011700号-1</a>
          </div>
          <div>
            地址：上海市杨浦区四平路<br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabHeader from '@/components/TabHeader.vue'
import HomeMain from './children/HomeMain.vue'
import DirectoryMediators from './children/DirectoryMediators.vue'
import AdjustmentProcess from './children/AdjustmentProcess.vue'
export default {
  data: function () {
    return {
      tabs: ['首页', '调解员名录', '调解流程'],
      currentIndex: 0
    }
  },
  components: {
    TabHeader,
    HomeMain,
    DirectoryMediators,
    AdjustmentProcess
  },
  methods: {
    tabChange: function (index) {
      this.currentIndex = index
    }
  }
}
</script>
<style>
  .main{
    width: 100%;
  }

.footer-info {
  padding-top: 15px;
  padding-bottom: 30px;
  background: white;
  border-top: 5px solid #e4393c;
}

.foot_main {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 150px;
  padding-top: 10px;
}

.foot_left {
  width: 100%;
  float: left;
  text-align: center;
  padding-top: 15px;
  line-height: 30px;
  padding-bottom: 30px;
}

.foot_right {
  width: 200px;
  float: right;
  text-align: center;
}
</style>
